<template>
<v-container>
    <v-row v-if="!resource">
        <v-col cols="12" class="pb-0">
            <div class="d-flex align-center justify-end">
                <v-btn text class="zindex-2 p-absolute top25 left15 pa-1" @click="back()" v-if="$vuetify.breakpoint.smAndUp">
                    <v-icon>mdi-chevron-left</v-icon>
                    Back
                </v-btn>

                <meo-favourite-button :section="section" :oldContact="contact"></meo-favourite-button>
            </div>
        </v-col>
    </v-row>

    <v-col cols="12" justify="center" align="center">
        <i class="neu-tea-leaf icon-size headingColor"></i>
        <h3 class="mb-1 headingColor">{{title}}</h3>
        <v-card-subtitle class="pa-0 mt-1 mb-2" v-if="sectionTitle">{{ sectionTitle }}</v-card-subtitle>
        <v-card-subtitle class="pa-0 mt-1 mb-2" v-if="audioTitle">{{ audioTitle }}</v-card-subtitle>
    </v-col>

    <v-row no-gutters>
        <v-col cols="12" class="px-8">
            <v-slider v-model="audioProgress" :max="audioDuration" hide-details @change="scrubAudio()"></v-slider>
            <div class="d-flex">
                <p class="text-left mb-0">{{ currentTimeText }}</p>
                <v-spacer />
                <p class="text-right">{{ totalTimeText }}</p>
            </div>
        </v-col>
    </v-row>

    <audio ref="audio" id="audio" preload="metadata"></audio>

    <v-row no-gutters class="mb-2" justify="center" align="center">
        <v-btn icon text class="px-3" color="var(--v-heading-base)" @click="skipBackward()">
            <v-icon size="30">mdi-rewind-outline</v-icon>
        </v-btn>

        <v-btn v-if="!playing" icon text class="mx-3" @click="playAudio()">
            <v-icon size="40" color="var(--v-heading-base)">mdi-play-circle-outline</v-icon>
        </v-btn>

        <v-btn v-else icon text class="mx-3" @click="playAudio()">
            <v-icon size="40" color="var(--v-heading-base)">mdi-pause-circle-outline</v-icon>
        </v-btn>

        <v-btn icon text class="px-3" color="var(--v-heading-base)" @click="skipForward()">
            <v-icon size="30">mdi-fast-forward-outline</v-icon>
        </v-btn>
    </v-row>
</v-container>
</template>

<script>
import meoFavouriteButton from "./meo-favourite-button.vue";

export default {
    name: "meo-meditation-player",
    props: {
        resource: {
            type: Boolean
        },
        audio: {
            type: Object,
        },
        fromDialog: {
            type: Boolean,
        },
        contact: {
            type: Object,
        },
        buttonControl: {
            type: Boolean,
        },
        section: {
            type: Object,
        }
    },
    components: { meoFavouriteButton },
    data() {
        return {
            audioProgress: 0,
            audioDuration: 0,
            currentTimeText: "0:00",
            totalTimeText: "0:00",
            playing: false,
            favourite: false,
        };
    },
    computed: {
        // moduleCheck(){
        //     // checks to see if module is the 1st one which is free and not a product so cannot show in favourites list
        //     // therefore hide the favourite button

        //     if(this.module._id ){
        //         return false
        //     } else {
        //           return true
        //     }
        // }
        title() {
            if (this.resource) {
                return "Additional Resources";
            } else {
                return (this.section?.data?.sectionName) || "Meditation";
            }
        },
        sectionTitle() {
            return (
                (this.section?.data?.heading) || (this.section?.title) || ""
            );
        },
        audioTitle() {
            return (this.audio && this.audio.title) || "";
        },
    },
    methods: {
        playAudio() {
            // if audio isnt playing then play it and change the playing data value to true
            // if it is playing then pause it and set the playing data value to false
            if (!this.playing) {
                this.$refs.audio.play();
            } else {
                this.$refs.audio.pause();
            }

            this.playing = !this.playing;
        },
        resetAudio() {
            this.playing = false;
            this.$refs.audio.pause();
            this.$refs.audio.currentTime = 0;
        },
        setDuration() {
            // when audio readyState has reached greater than 2 (loaded audio metadata), set audioDuration
            if (this.$refs.audio.readyState >= 2) {
                this.totalTimeText = this.millisToMinutesAndSeconds(this.$refs.audio.duration * 1000);
                this.audioDuration = this.$refs.audio.duration;
            }
        },
        millisToMinutesAndSeconds(millis) {
            // takes in a time in milliseconds and outputs a string showing minute & seconds format (0:00 ect.)
            var minutes = Math.floor(millis / 60000);
            var seconds = ((millis % 60000) / 1000).toFixed(0);
            return seconds == 60 ? minutes + 1 + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
        },
        scrubAudio() {
            // sets the current time on the audio element to the data value of audioProgress which is linked by v-model
            // to the slider component which is scaled via max to the length of the audio.
            this.$refs.audio.currentTime = this.audioProgress;
        },
        skipForward() {
            // adds 10 to the currentTime value on audio element then sets the audioProgress data to the now 10s further currentTime
            this.$refs.audio.currentTime = this.$refs.audio.currentTime + 10;
            this.audioProgress = this.$refs.audio.currentTime;
        },
        skipBackward() {
            //same as skipForward but minus 10
            this.$refs.audio.currentTime = this.$refs.audio.currentTime - 10;
            this.audioProgress = this.$refs.audio.currentTime;
        },
        playTimeControl() {
            const audio = document.querySelector("#audio");

            //listen for an update to the time values and then update the audioProgress data value so the slider adjusts
            // then set the currentTimeText value to the correctly formatted current Time
            // if the progress is greater than 60% of total duration then emit true so the complete button shows

            audio.addEventListener("timeupdate", (e) => {
                let progress = e.target.currentTime;

                this.audioProgress = progress;
                this.currentTimeText = this.millisToMinutesAndSeconds(progress * 1000);

                // this.millisToMinutesAndSeconds(progress * 1000);

                if (this.audioDuration > 0) {
                    if (this.audioProgress > this.audioDuration * 0.6) {
                        if (!this.buttonControl) {
                            this.$emit("progressCheck", true);
                            console.log("emit time");
                        }
                    }
                }
            });
        },
        async loadAudio() {
            // get audio source from the backend
            // Need to add "from" so that Fluro knows to get it from the purchased product
            let audio = this.section?.data?.audio || this.audio;

            let url = this.$fluro.asset.getUrl(audio, {
                from: this.$route.params.id,
            });

            this.$nextTick(function() {
                // assign the audio source to the audio html element
                this.$refs.audio.src = url;
                // run the load method on the audio element to prepare audio file
                this.$refs.audio.load();
                // add listener to check if data has loaded if so set and format the duration time
                // added due to duration setting as NaN as the data had not loaded
                this.$refs.audio.addEventListener("loadeddata", this.setDuration);
            });

            await this.playTimeControl();
            await this.playAudio();
        },
        back() {
            this.$emit('back');
        },
    },

    async mounted() {
        if (!this.fromDialog) {
            await this.loadAudio();
        }

    },
};
</script>

<style lang="scss">
.v-icon::after {
    background-color: rgba(0, 0, 0, 0) !important;
}
.icon-size {
    font-size: 36px !important;
}
.favourite-size {
    font-size: 24px;
}

.icon-button {
    background-color: rgba(0, 0, 0, 0) !important;
}
.headingColor {
    color: var(--v-heading-base);
}
</style>
